const transitionDelay = 500

exports.onServiceWorkerUpdateReady = () => {
  const answer = window.confirm( 
    `Ce site a été mis à jour. ` + 
    `Rechargez la page pour avoir la dernière version ?` 
  )
  if (answer === true) {
    window.location.reload()
  }
}

exports.shouldUpdateScroll = ({ 
  routerProps: { location }, 
  getSavedScrollPosition, 
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout( 
      () => window.scrollTo(...(savedPosition || [0, 0])), 
      transitionDelay 
    )
  }    
  return false
}