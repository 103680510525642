import React from "react"
import { Location } from "@reach/router"

import Transition from "../components/transition"

import { injectIntl } from "gatsby-plugin-intl"

import "../styles/index.scss"
import * as layoutStyles from "./layout.module.scss"

const Layout = (props, { location }) => {
  function checkProps(props) {
    return props ? props : ""
  }


  checkProps(props)
  return (
    <Location>
      {({ location }) => (
        <div className={layoutStyles.container}>
          
          <div className={layoutStyles.content}>
            <Transition location={location}>{props.children}</Transition>
          </div>

        </div>
      )}
    </Location>
  )
}

export default injectIntl(Layout)
