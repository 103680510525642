import React from "react"
import { Location } from "@reach/router"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const getTransitionStyles = {
  entering: {
    //position: 'absolute',
    //opacity: 0.5,
  },
  entered: {
    //transition: `all 300ms ease-in-out`,
    //opacity: 1,
  },
  exiting: {
    //transition: `all 300ms ease-out`,
    //opacity: 0.5,
  },
}

class Transition extends React.PureComponent {
  render() {
    const { children } = this.props

    return (
      <Location>
        {({ location }) => (
          <TransitionGroup>
            <ReactTransition
              key={location.key}
              timeout={{
                enter: 0,
                exit: 0,
              }}
            >
              {status => (
                <div
                  style={{
                    ...getTransitionStyles[status],
                  }}
                >
                  {children}
                </div>
              )}
            </ReactTransition>
          </TransitionGroup>
        )}
      </Location>
    )
  }
}

export default Transition
