import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { Helmet } from "react-helmet"

import Transition from "../components/transition"

import "./layout.css"

import BackgroundImage from "gatsby-background-image"

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        fonds: allFile(filter: { sourceInstanceName: { eq: "fond" } }) {
          totalCount
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 2560, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    
    render={data => (
      <>
        <BackgroundImage
          Tag="section"
          fluid={data.fonds.edges[6].node.childImageSharp.fluid}
          alt={"fond de la section bureau"}
          backgroundColor={`#040e18`}
          backgroundRepeat={"repeat-y"}
          backgroundPosition={`bottom center`}
        >
          <Transition location={location}>{children}</Transition>
        </BackgroundImage>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
